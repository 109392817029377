/* components/App.svelte generated by Svelte v3.35.0 */
import {
	SvelteComponent,
	add_flush_callback,
	append,
	attr,
	bind,
	binding_callbacks,
	create_component,
	destroy_component,
	detach,
	element,
	init,
	insert,
	mount_component,
	safe_not_equal,
	space,
	transition_in,
	transition_out
} from "svelte/internal";

import {
	getPlano,
	getEventos,
	getDePara,
	updateDePara as remoteUpdateDePara
} from "../js/remote";

import TipoSelector from "./TipoSelector.svelte";
import RemoteTable from "./RemoteTable.svelte";

function create_fragment(ctx) {
	let div1;
	let tiposelector;
	let updating_selectedTipo;
	let t0;
	let div0;
	let t2;
	let div2;
	let remotetable;
	let updating_contentPromise;
	let updating_plano;
	let current;

	function tiposelector_selectedTipo_binding(value) {
		/*tiposelector_selectedTipo_binding*/ ctx[4](value);
	}

	let tiposelector_props = {};

	if (/*selectedTipo*/ ctx[0] !== void 0) {
		tiposelector_props.selectedTipo = /*selectedTipo*/ ctx[0];
	}

	tiposelector = new TipoSelector({ props: tiposelector_props });
	binding_callbacks.push(() => bind(tiposelector, "selectedTipo", tiposelector_selectedTipo_binding));

	function remotetable_contentPromise_binding(value) {
		/*remotetable_contentPromise_binding*/ ctx[5](value);
	}

	function remotetable_plano_binding(value) {
		/*remotetable_plano_binding*/ ctx[6](value);
	}

	let remotetable_props = {};

	if (/*processedDeParaPromise*/ ctx[2] !== void 0) {
		remotetable_props.contentPromise = /*processedDeParaPromise*/ ctx[2];
	}

	if (/*plano*/ ctx[1] !== void 0) {
		remotetable_props.plano = /*plano*/ ctx[1];
	}

	remotetable = new RemoteTable({ props: remotetable_props });
	binding_callbacks.push(() => bind(remotetable, "contentPromise", remotetable_contentPromise_binding));
	binding_callbacks.push(() => bind(remotetable, "plano", remotetable_plano_binding));
	remotetable.$on("updateMap", /*updateMap_handler*/ ctx[7]);

	return {
		c() {
			div1 = element("div");
			create_component(tiposelector.$$.fragment);
			t0 = space();
			div0 = element("div");
			div0.innerHTML = `<button class="button rounded" data-role="ripple">Adiciona</button>`;
			t2 = space();
			div2 = element("div");
			create_component(remotetable.$$.fragment);
			attr(div0, "class", "row-x pad svelte-gujqn3");
			attr(div1, "class", "header");
			attr(div2, "class", "overflow-area svelte-gujqn3");
		},
		m(target, anchor) {
			insert(target, div1, anchor);
			mount_component(tiposelector, div1, null);
			append(div1, t0);
			append(div1, div0);
			insert(target, t2, anchor);
			insert(target, div2, anchor);
			mount_component(remotetable, div2, null);
			current = true;
		},
		p(ctx, [dirty]) {
			const tiposelector_changes = {};

			if (!updating_selectedTipo && dirty & /*selectedTipo*/ 1) {
				updating_selectedTipo = true;
				tiposelector_changes.selectedTipo = /*selectedTipo*/ ctx[0];
				add_flush_callback(() => updating_selectedTipo = false);
			}

			tiposelector.$set(tiposelector_changes);
			const remotetable_changes = {};

			if (!updating_contentPromise && dirty & /*processedDeParaPromise*/ 4) {
				updating_contentPromise = true;
				remotetable_changes.contentPromise = /*processedDeParaPromise*/ ctx[2];
				add_flush_callback(() => updating_contentPromise = false);
			}

			if (!updating_plano && dirty & /*plano*/ 2) {
				updating_plano = true;
				remotetable_changes.plano = /*plano*/ ctx[1];
				add_flush_callback(() => updating_plano = false);
			}

			remotetable.$set(remotetable_changes);
		},
		i(local) {
			if (current) return;
			transition_in(tiposelector.$$.fragment, local);
			transition_in(remotetable.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(tiposelector.$$.fragment, local);
			transition_out(remotetable.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			if (detaching) detach(div1);
			destroy_component(tiposelector);
			if (detaching) detach(t2);
			if (detaching) detach(div2);
			destroy_component(remotetable);
		}
	};
}

function instance($$self, $$props, $$invalidate) {
	let selectedTipo;
	let plano = [];
	let processedDeParaPromise;

	const planoPromise = getPlano().then(_plano => {
		$$invalidate(1, plano = _plano);
		return plano;
	});

	const pegaConta = (plano, chamada) => plano.find(({ chamada: _c }) => chamada === _c);

	const processaDePara = (plano, eventos, dePara) => dePara.map(({ de, para }) => ({
		evento: de,
		descricaoEvento: eventos[de] || "",
		chamada: para,
		classificacao: (pegaConta(plano, para) || {}).classificacao || "",
		descricaoConta: (pegaConta(plano, para) || {}).descricao || ""
	}));

	const updateDePara = async (evento, chamada) => {
		const processedDePara = await processedDeParaPromise;

		$$invalidate(2, processedDeParaPromise = Promise.resolve(processedDePara.map(info => info.evento === evento
		? {
				...info,
				...(() => {
					const { descricao, ...resto } = pegaConta(plano, chamada);
					return { ...resto, descricaoConta: descricao };
				})()
			}
		: info)));

		await remoteUpdateDePara(selectedTipo, evento, chamada);
	};

	function tiposelector_selectedTipo_binding(value) {
		selectedTipo = value;
		$$invalidate(0, selectedTipo);
	}

	function remotetable_contentPromise_binding(value) {
		processedDeParaPromise = value;
		($$invalidate(2, processedDeParaPromise), $$invalidate(0, selectedTipo));
	}

	function remotetable_plano_binding(value) {
		plano = value;
		$$invalidate(1, plano);
	}

	const updateMap_handler = ({ detail: { evento, chamada } }) => {
		updateDePara(evento, chamada);
	};

	$$self.$$.update = () => {
		if ($$self.$$.dirty & /*selectedTipo*/ 1) {
			$: if (selectedTipo) {
				$$invalidate(2, processedDeParaPromise = Promise.all([planoPromise, getEventos(), getDePara(selectedTipo)]).then(([plano, eventos, dePara]) => processaDePara(plano, eventos.reduce((obj, { cod, descricao }) => ({ ...obj, [cod]: descricao }), {}), dePara)).catch(e => {
					console.log(e);
					throw e;
				}));
			}
		}
	};

	return [
		selectedTipo,
		plano,
		processedDeParaPromise,
		updateDePara,
		tiposelector_selectedTipo_binding,
		remotetable_contentPromise_binding,
		remotetable_plano_binding,
		updateMap_handler
	];
}

class App extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance, create_fragment, safe_not_equal, {});
	}
}

export default App;