export const getPlano = (): Promise<{}[]> =>
  fetch('/plano').then(res => res.json())

type Evento = {
  cod: number
  descricao: string
}
export const getEventos = (): Promise<Evento[]> =>
  fetch('/eventos').then(res => res.json())

export const getDePara = (tipo: number): Promise<object> =>
  fetch(`/depara/${tipo}`).then(res => res.json())

export const updateDePara = (
  tipo: number,
  de: number,
  para: number
): Promise<boolean> =>
  fetch(`/depara/${tipo}/${de}/${para}`, { method: 'PUT' }).then(res =>
    res.json()
  )
