/* components/TipoSelector.svelte generated by Svelte v3.35.0 */
import {
	SvelteComponent,
	add_render_callback,
	append,
	attr,
	destroy_each,
	detach,
	element,
	init,
	insert,
	listen,
	noop,
	safe_not_equal,
	select_option,
	select_value,
	space,
	text
} from "svelte/internal";

function get_each_context(ctx, list, i) {
	const child_ctx = ctx.slice();
	child_ctx[4] = list[i];
	child_ctx[6] = i;
	return child_ctx;
}

// (11:4) {#each tipos as tipo, i}
function create_each_block(ctx) {
	let option;
	let t_value = /*tipo*/ ctx[4] + "";
	let t;
	let option_value_value;

	return {
		c() {
			option = element("option");
			t = text(t_value);
			option.__value = option_value_value = /*i*/ ctx[6];
			option.value = option.__value;
		},
		m(target, anchor) {
			insert(target, option, anchor);
			append(option, t);
		},
		p: noop,
		d(detaching) {
			if (detaching) detach(option);
		}
	};
}

function create_fragment(ctx) {
	let div1;
	let div0;
	let t1;
	let select;
	let mounted;
	let dispose;
	let each_value = /*tipos*/ ctx[1];
	let each_blocks = [];

	for (let i = 0; i < each_value.length; i += 1) {
		each_blocks[i] = create_each_block(get_each_context(ctx, each_value, i));
	}

	return {
		c() {
			div1 = element("div");
			div0 = element("div");
			div0.textContent = "Tipo:";
			t1 = space();
			select = element("select");

			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].c();
			}

			attr(div0, "class", "svelte-viz9v2");
			attr(select, "name", "tipo");
			attr(select, "class", "svelte-viz9v2");
			if (/*selectedTipoIdx*/ ctx[0] === void 0) add_render_callback(() => /*select_change_handler*/ ctx[3].call(select));
			attr(div1, "class", "row-x svelte-viz9v2");
		},
		m(target, anchor) {
			insert(target, div1, anchor);
			append(div1, div0);
			append(div1, t1);
			append(div1, select);

			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].m(select, null);
			}

			select_option(select, /*selectedTipoIdx*/ ctx[0]);

			if (!mounted) {
				dispose = listen(select, "change", /*select_change_handler*/ ctx[3]);
				mounted = true;
			}
		},
		p(ctx, [dirty]) {
			if (dirty & /*tipos*/ 2) {
				each_value = /*tipos*/ ctx[1];
				let i;

				for (i = 0; i < each_value.length; i += 1) {
					const child_ctx = get_each_context(ctx, each_value, i);

					if (each_blocks[i]) {
						each_blocks[i].p(child_ctx, dirty);
					} else {
						each_blocks[i] = create_each_block(child_ctx);
						each_blocks[i].c();
						each_blocks[i].m(select, null);
					}
				}

				for (; i < each_blocks.length; i += 1) {
					each_blocks[i].d(1);
				}

				each_blocks.length = each_value.length;
			}

			if (dirty & /*selectedTipoIdx*/ 1) {
				select_option(select, /*selectedTipoIdx*/ ctx[0]);
			}
		},
		i: noop,
		o: noop,
		d(detaching) {
			if (detaching) detach(div1);
			destroy_each(each_blocks, detaching);
			mounted = false;
			dispose();
		}
	};
}

function instance($$self, $$props, $$invalidate) {
	let { selectedTipo } = $$props;
	let selectedTipoIdx;
	const tipos = ["FMP", "AMBE", "PMAQ", "OBRAS"];

	function select_change_handler() {
		selectedTipoIdx = select_value(this);
		$$invalidate(0, selectedTipoIdx);
	}

	$$self.$$set = $$props => {
		if ("selectedTipo" in $$props) $$invalidate(2, selectedTipo = $$props.selectedTipo);
	};

	$$self.$$.update = () => {
		if ($$self.$$.dirty & /*selectedTipoIdx*/ 1) {
			$: $$invalidate(2, selectedTipo = selectedTipoIdx + 1);
		}
	};

	return [selectedTipoIdx, tipos, selectedTipo, select_change_handler];
}

class TipoSelector extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance, create_fragment, safe_not_equal, { selectedTipo: 2 });
	}
}

export default TipoSelector;