/* components/RemoteTable.svelte generated by Svelte v3.35.0 */
import {
	SvelteComponent,
	append,
	attr,
	binding_callbacks,
	create_component,
	destroy_component,
	destroy_each,
	detach,
	element,
	handle_promise,
	init,
	insert,
	listen,
	mount_component,
	noop,
	run_all,
	safe_not_equal,
	set_data,
	space,
	text,
	transition_in,
	transition_out
} from "svelte/internal";

import { createEventDispatcher } from "svelte";
import App from "./App.svelte";
import ContaDropdown from "./ContaDropdown.svelte";

function get_each_context(ctx, list, i) {
	const child_ctx = ctx.slice();
	child_ctx[24] = list[i];
	return child_ctx;
}

// (1:0) <script>   import { createEventDispatcher }
function create_catch_block(ctx) {
	return { c: noop, m: noop, p: noop, d: noop };
}

// (161:2) {:then list}
function create_then_block(ctx) {
	let tbody;
	let each_value = /*list*/ ctx[23];
	let each_blocks = [];

	for (let i = 0; i < each_value.length; i += 1) {
		each_blocks[i] = create_each_block(get_each_context(ctx, each_value, i));
	}

	return {
		c() {
			tbody = element("tbody");

			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].c();
			}
		},
		m(target, anchor) {
			insert(target, tbody, anchor);

			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].m(tbody, null);
			}
		},
		p(ctx, dirty) {
			if (dirty & /*baitAndSwitch, sortedDeParaPromise*/ 576) {
				each_value = /*list*/ ctx[23];
				let i;

				for (i = 0; i < each_value.length; i += 1) {
					const child_ctx = get_each_context(ctx, each_value, i);

					if (each_blocks[i]) {
						each_blocks[i].p(child_ctx, dirty);
					} else {
						each_blocks[i] = create_each_block(child_ctx);
						each_blocks[i].c();
						each_blocks[i].m(tbody, null);
					}
				}

				for (; i < each_blocks.length; i += 1) {
					each_blocks[i].d(1);
				}

				each_blocks.length = each_value.length;
			}
		},
		d(detaching) {
			if (detaching) detach(tbody);
			destroy_each(each_blocks, detaching);
		}
	};
}

// (163:6) {#each list as item}
function create_each_block(ctx) {
	let tr;
	let td0;
	let t0_value = /*item*/ ctx[24].evento + "";
	let t0;
	let t1;
	let td1;
	let t2_value = /*item*/ ctx[24].descricaoEvento + "";
	let t2;
	let t3;
	let td2;
	let t4_value = /*item*/ ctx[24].chamada + "";
	let t4;
	let t5;
	let td3;
	let t6_value = /*item*/ ctx[24].classificacao + "";
	let t6;
	let t7;
	let td4;
	let t8_value = /*item*/ ctx[24].descricaoConta + "";
	let t8;
	let t9;
	let mounted;
	let dispose;

	function click_handler_5(...args) {
		return /*click_handler_5*/ ctx[16](/*item*/ ctx[24], ...args);
	}

	return {
		c() {
			tr = element("tr");
			td0 = element("td");
			t0 = text(t0_value);
			t1 = space();
			td1 = element("td");
			t2 = text(t2_value);
			t3 = space();
			td2 = element("td");
			t4 = text(t4_value);
			t5 = space();
			td3 = element("td");
			t6 = text(t6_value);
			t7 = space();
			td4 = element("td");
			t8 = text(t8_value);
			t9 = space();
		},
		m(target, anchor) {
			insert(target, tr, anchor);
			append(tr, td0);
			append(td0, t0);
			append(tr, t1);
			append(tr, td1);
			append(td1, t2);
			append(tr, t3);
			append(tr, td2);
			append(td2, t4);
			append(tr, t5);
			append(tr, td3);
			append(td3, t6);
			append(tr, t7);
			append(tr, td4);
			append(td4, t8);
			append(tr, t9);

			if (!mounted) {
				dispose = listen(tr, "click", click_handler_5);
				mounted = true;
			}
		},
		p(new_ctx, dirty) {
			ctx = new_ctx;
			if (dirty & /*sortedDeParaPromise*/ 64 && t0_value !== (t0_value = /*item*/ ctx[24].evento + "")) set_data(t0, t0_value);
			if (dirty & /*sortedDeParaPromise*/ 64 && t2_value !== (t2_value = /*item*/ ctx[24].descricaoEvento + "")) set_data(t2, t2_value);
			if (dirty & /*sortedDeParaPromise*/ 64 && t4_value !== (t4_value = /*item*/ ctx[24].chamada + "")) set_data(t4, t4_value);
			if (dirty & /*sortedDeParaPromise*/ 64 && t6_value !== (t6_value = /*item*/ ctx[24].classificacao + "")) set_data(t6, t6_value);
			if (dirty & /*sortedDeParaPromise*/ 64 && t8_value !== (t8_value = /*item*/ ctx[24].descricaoConta + "")) set_data(t8, t8_value);
		},
		d(detaching) {
			if (detaching) detach(tr);
			mounted = false;
			dispose();
		}
	};
}

// (157:30)      <tr class="loading">       <td colspan="5">Carregando de para...</td>     </tr>   {:then list}
function create_pending_block(ctx) {
	let tr;

	return {
		c() {
			tr = element("tr");
			tr.innerHTML = `<td colspan="5">Carregando de para...</td>`;
			attr(tr, "class", "loading");
		},
		m(target, anchor) {
			insert(target, tr, anchor);
		},
		p: noop,
		d(detaching) {
			if (detaching) detach(tr);
		}
	};
}

function create_fragment(ctx) {
	let table;
	let thead;
	let tr;
	let th0;
	let t0;
	let i0;
	let i0_class_value;
	let i0_transparent_value;
	let t1;
	let th1;
	let t2;
	let i1;
	let i1_class_value;
	let i1_transparent_value;
	let t3;
	let th2;
	let t4;
	let i2;
	let i2_class_value;
	let i2_transparent_value;
	let t5;
	let th3;
	let t6;
	let i3;
	let i3_class_value;
	let i3_transparent_value;
	let t7;
	let th4;
	let t8;
	let i4;
	let i4_class_value;
	let i4_transparent_value;
	let t9;
	let promise;
	let t10;
	let div;
	let td;
	let contadropdown;
	let current;
	let mounted;
	let dispose;

	let info = {
		ctx,
		current: null,
		token: null,
		hasCatch: false,
		pending: create_pending_block,
		then: create_then_block,
		catch: create_catch_block,
		value: 23
	};

	handle_promise(promise = /*sortedDeParaPromise*/ ctx[6], info);

	contadropdown = new ContaDropdown({
			props: { plano: /*plano*/ ctx[0], chamada: "" }
		});

	contadropdown.$on("select", /*select_handler*/ ctx[17]);

	return {
		c() {
			table = element("table");
			thead = element("thead");
			tr = element("tr");
			th0 = element("th");
			t0 = text("#\n        ");
			i0 = element("i");
			t1 = space();
			th1 = element("th");
			t2 = text("Evento\n        ");
			i1 = element("i");
			t3 = space();
			th2 = element("th");
			t4 = text("Chamada\n        ");
			i2 = element("i");
			t5 = space();
			th3 = element("th");
			t6 = text("Classificação\n        ");
			i3 = element("i");
			t7 = space();
			th4 = element("th");
			t8 = text("Descrição\n        ");
			i4 = element("i");
			t9 = space();
			info.block.c();
			t10 = space();
			div = element("div");
			td = element("td");
			create_component(contadropdown.$$.fragment);
			attr(i0, "class", i0_class_value = "sort-icon fas fa-chevron-" + (/*sortDirectionDesc*/ ctx[4] ? "up" : "down") + " svelte-zfm96b");
			attr(i0, "transparent", i0_transparent_value = /*selectedTipoSort*/ ctx[3] != "en" ? true : null);
			attr(th0, "scope", "col");
			attr(th0, "class", "svelte-zfm96b");
			attr(i1, "class", i1_class_value = "sort-icon fas fa-chevron-" + (/*sortDirectionDesc*/ ctx[4] ? "up" : "down") + " svelte-zfm96b");
			attr(i1, "transparent", i1_transparent_value = /*selectedTipoSort*/ ctx[3] != "ed" ? true : null);
			attr(th1, "scope", "col");
			attr(th1, "class", "svelte-zfm96b");
			attr(i2, "class", i2_class_value = "sort-icon fas fa-chevron-" + (/*sortDirectionDesc*/ ctx[4] ? "up" : "down") + " svelte-zfm96b");
			attr(i2, "transparent", i2_transparent_value = /*selectedTipoSort*/ ctx[3] != "cm" ? true : null);
			attr(th2, "scope", "col");
			attr(th2, "class", "svelte-zfm96b");
			attr(i3, "class", i3_class_value = "sort-icon fas fa-chevron-" + (/*sortDirectionDesc*/ ctx[4] ? "up" : "down") + " svelte-zfm96b");
			attr(i3, "transparent", i3_transparent_value = /*selectedTipoSort*/ ctx[3] != "cn" ? true : null);
			attr(th3, "scope", "col");
			attr(th3, "class", "svelte-zfm96b");
			attr(i4, "class", i4_class_value = "sort-icon fas fa-chevron-" + (/*sortDirectionDesc*/ ctx[4] ? "up" : "down") + " svelte-zfm96b");
			attr(i4, "transparent", i4_transparent_value = /*selectedTipoSort*/ ctx[3] != "cd" ? true : null);
			attr(th4, "scope", "col");
			attr(th4, "class", "svelte-zfm96b");
			attr(table, "class", "table striped table-border svelte-zfm96b");
			attr(td, "colspan", "3");
			attr(td, "class", "no-pad svelte-zfm96b");
			attr(div, "class", "hidden svelte-zfm96b");
		},
		m(target, anchor) {
			insert(target, table, anchor);
			append(table, thead);
			append(thead, tr);
			append(tr, th0);
			append(th0, t0);
			append(th0, i0);
			append(tr, t1);
			append(tr, th1);
			append(th1, t2);
			append(th1, i1);
			append(tr, t3);
			append(tr, th2);
			append(th2, t4);
			append(th2, i2);
			append(tr, t5);
			append(tr, th3);
			append(th3, t6);
			append(th3, i3);
			append(tr, t7);
			append(tr, th4);
			append(th4, t8);
			append(th4, i4);
			append(table, t9);
			info.block.m(table, info.anchor = null);
			info.mount = () => table;
			info.anchor = null;
			insert(target, t10, anchor);
			insert(target, div, anchor);
			append(div, td);
			mount_component(contadropdown, td, null);
			/*td_binding*/ ctx[18](td);
			/*div_binding*/ ctx[19](div);
			current = true;

			if (!mounted) {
				dispose = [
					listen(th0, "click", /*click_handler*/ ctx[11]),
					listen(th1, "click", /*click_handler_1*/ ctx[12]),
					listen(th2, "click", /*click_handler_2*/ ctx[13]),
					listen(th3, "click", /*click_handler_3*/ ctx[14]),
					listen(th4, "click", /*click_handler_4*/ ctx[15])
				];

				mounted = true;
			}
		},
		p(new_ctx, [dirty]) {
			ctx = new_ctx;

			if (!current || dirty & /*sortDirectionDesc*/ 16 && i0_class_value !== (i0_class_value = "sort-icon fas fa-chevron-" + (/*sortDirectionDesc*/ ctx[4] ? "up" : "down") + " svelte-zfm96b")) {
				attr(i0, "class", i0_class_value);
			}

			if (!current || dirty & /*selectedTipoSort*/ 8 && i0_transparent_value !== (i0_transparent_value = /*selectedTipoSort*/ ctx[3] != "en" ? true : null)) {
				attr(i0, "transparent", i0_transparent_value);
			}

			if (!current || dirty & /*sortDirectionDesc*/ 16 && i1_class_value !== (i1_class_value = "sort-icon fas fa-chevron-" + (/*sortDirectionDesc*/ ctx[4] ? "up" : "down") + " svelte-zfm96b")) {
				attr(i1, "class", i1_class_value);
			}

			if (!current || dirty & /*selectedTipoSort*/ 8 && i1_transparent_value !== (i1_transparent_value = /*selectedTipoSort*/ ctx[3] != "ed" ? true : null)) {
				attr(i1, "transparent", i1_transparent_value);
			}

			if (!current || dirty & /*sortDirectionDesc*/ 16 && i2_class_value !== (i2_class_value = "sort-icon fas fa-chevron-" + (/*sortDirectionDesc*/ ctx[4] ? "up" : "down") + " svelte-zfm96b")) {
				attr(i2, "class", i2_class_value);
			}

			if (!current || dirty & /*selectedTipoSort*/ 8 && i2_transparent_value !== (i2_transparent_value = /*selectedTipoSort*/ ctx[3] != "cm" ? true : null)) {
				attr(i2, "transparent", i2_transparent_value);
			}

			if (!current || dirty & /*sortDirectionDesc*/ 16 && i3_class_value !== (i3_class_value = "sort-icon fas fa-chevron-" + (/*sortDirectionDesc*/ ctx[4] ? "up" : "down") + " svelte-zfm96b")) {
				attr(i3, "class", i3_class_value);
			}

			if (!current || dirty & /*selectedTipoSort*/ 8 && i3_transparent_value !== (i3_transparent_value = /*selectedTipoSort*/ ctx[3] != "cn" ? true : null)) {
				attr(i3, "transparent", i3_transparent_value);
			}

			if (!current || dirty & /*sortDirectionDesc*/ 16 && i4_class_value !== (i4_class_value = "sort-icon fas fa-chevron-" + (/*sortDirectionDesc*/ ctx[4] ? "up" : "down") + " svelte-zfm96b")) {
				attr(i4, "class", i4_class_value);
			}

			if (!current || dirty & /*selectedTipoSort*/ 8 && i4_transparent_value !== (i4_transparent_value = /*selectedTipoSort*/ ctx[3] != "cd" ? true : null)) {
				attr(i4, "transparent", i4_transparent_value);
			}

			info.ctx = ctx;

			if (dirty & /*sortedDeParaPromise*/ 64 && promise !== (promise = /*sortedDeParaPromise*/ ctx[6]) && handle_promise(promise, info)) {
				
			} else {
				const child_ctx = ctx.slice();
				child_ctx[23] = info.resolved;
				info.block.p(child_ctx, dirty);
			}

			const contadropdown_changes = {};
			if (dirty & /*plano*/ 1) contadropdown_changes.plano = /*plano*/ ctx[0];
			contadropdown.$set(contadropdown_changes);
		},
		i(local) {
			if (current) return;
			transition_in(contadropdown.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(contadropdown.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			if (detaching) detach(table);
			info.block.d();
			info.token = null;
			info = null;
			if (detaching) detach(t10);
			if (detaching) detach(div);
			destroy_component(contadropdown);
			/*td_binding*/ ctx[18](null);
			/*div_binding*/ ctx[19](null);
			mounted = false;
			run_all(dispose);
		}
	};
}

function instance($$self, $$props, $$invalidate) {
	let sortedDeParaPromise;
	let { contentPromise } = $$props, { plano } = $$props;

	let editingEvento,
		editingElement,
		contaDropdownElement,
		contaDropdownContainerElement;

	let selectedTipoSort = "en";
	let sortDirectionDesc = false;
	const dispatch = createEventDispatcher();

	const setSort = tipoSort => {
		if (tipoSort === selectedTipoSort) {
			$$invalidate(4, sortDirectionDesc = !sortDirectionDesc);
		} else {
			$$invalidate(4, sortDirectionDesc = false);
			$$invalidate(3, selectedTipoSort = tipoSort);
		}
	};

	const updateDePara = chamada => {
		dispatch("updateMap", { evento: editingEvento, chamada });
	};

	const sortDePara = async (tipoSort, desc) => {
		if (!tipoSort) return [];
		const processedDePara = await contentPromise;
		if (!processedDePara || processedDePara.length === 0) return [];

		return processedDePara.sort((a, b) => {
			switch (tipoSort) {
				case "en":
					return (a.evento - b.evento) * (desc ? -1 : 1);
				case "ed":
					return a.descricaoEvento.localeCompare(b.descricaoEvento) * (desc ? -1 : 1);
				case "cn":
					return a.classificacao.localeCompare(b.classificacao) * (desc ? -1 : 1);
				case "cd":
					return a.descricaoConta.localeCompare(b.descricaoConta) * (desc ? -1 : 1);
				case "cm":
					return (a.chamada - b.chamada) * (desc ? -1 : 1);
			}
		});
	};

	const baitAndSwitch = (e, evento, chamada) => {
		if (editingEvento || editingEvento == evento) {
			//tem algo sendo editado. Retorna.
			editingElement.append(...Array.from(contaDropdownContainerElement.querySelectorAll("td")));

			// volta ao estado original
			contaDropdownContainerElement.append(contaDropdownElement);

			$$invalidate(5, editingEvento = editingElement = undefined);
			return;
		}

		const tr = editingElement = e.target.closest("tr");
		const tds = Array.from(tr.querySelectorAll("td")).slice(-3);

		// mostra o dropdown
		contaDropdownContainerElement.append(...tds);

		contaDropdownElement.querySelector("#main").setFilter(chamada);
		tr.append(contaDropdownElement);
		$$invalidate(5, editingEvento = evento);
	};

	const click_handler = () => {
		setSort("en");
	};

	const click_handler_1 = () => {
		setSort("ed");
	};

	const click_handler_2 = () => {
		setSort("cm");
	};

	const click_handler_3 = () => {
		setSort("cn");
	};

	const click_handler_4 = () => {
		setSort("cd");
	};

	const click_handler_5 = (item, e) => baitAndSwitch(e, item.evento, item.chamada);

	const select_handler = ({ detail }) => {
		updateDePara(detail);
		$$invalidate(5, editingEvento = undefined);
	};

	function td_binding($$value) {
		binding_callbacks[$$value ? "unshift" : "push"](() => {
			contaDropdownElement = $$value;
			$$invalidate(1, contaDropdownElement);
		});
	}

	function div_binding($$value) {
		binding_callbacks[$$value ? "unshift" : "push"](() => {
			contaDropdownContainerElement = $$value;
			$$invalidate(2, contaDropdownContainerElement);
		});
	}

	$$self.$$set = $$props => {
		if ("contentPromise" in $$props) $$invalidate(10, contentPromise = $$props.contentPromise);
		if ("plano" in $$props) $$invalidate(0, plano = $$props.plano);
	};

	$$self.$$.update = () => {
		if ($$self.$$.dirty & /*selectedTipoSort, sortDirectionDesc, contentPromise*/ 1048) {
			$: $$invalidate(6, sortedDeParaPromise = sortDePara(selectedTipoSort, sortDirectionDesc, contentPromise));
		}

		if ($$self.$$.dirty & /*contentPromise, contaDropdownContainerElement, contaDropdownElement*/ 1030) {
			$: {
				contentPromise;
				$$invalidate(5, editingEvento = editingElement = undefined);

				if (contaDropdownContainerElement) {
					Array.from(contaDropdownContainerElement.querySelectorAll("td")).forEach(td => td.remove());
					contaDropdownContainerElement.append(contaDropdownElement);
				}
			}
		}
	};

	return [
		plano,
		contaDropdownElement,
		contaDropdownContainerElement,
		selectedTipoSort,
		sortDirectionDesc,
		editingEvento,
		sortedDeParaPromise,
		setSort,
		updateDePara,
		baitAndSwitch,
		contentPromise,
		click_handler,
		click_handler_1,
		click_handler_2,
		click_handler_3,
		click_handler_4,
		click_handler_5,
		select_handler,
		td_binding,
		div_binding
	];
}

class RemoteTable extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance, create_fragment, safe_not_equal, { contentPromise: 10, plano: 0 });
	}
}

export default RemoteTable;