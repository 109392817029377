/* components/ContaDropdown.svelte generated by Svelte v3.35.0 */
import {
	SvelteComponent,
	append,
	attr,
	binding_callbacks,
	destroy_each,
	detach,
	element,
	init,
	insert,
	listen,
	noop,
	run_all,
	safe_not_equal,
	set_data,
	set_input_value,
	space,
	text
} from "svelte/internal";

import { onMount, createEventDispatcher } from "svelte";

function get_each_context(ctx, list, i) {
	const child_ctx = ctx.slice();
	child_ctx[1] = list[i].chamada;
	child_ctx[13] = list[i].classificacao;
	child_ctx[14] = list[i].descricao;
	return child_ctx;
}

// (45:4) {#each filteredPlano as { chamada, classificacao, descricao }}
function create_each_block(ctx) {
	let div4;
	let div2;
	let div0;
	let t0_value = /*chamada*/ ctx[1] + "";
	let t0;
	let t1;
	let div1;
	let t2_value = /*classificacao*/ ctx[13] + "";
	let t2;
	let t3;
	let div3;
	let t4_value = /*descricao*/ ctx[14] + "";
	let t4;
	let t5;
	let mounted;
	let dispose;

	function click_handler() {
		return /*click_handler*/ ctx[9](/*chamada*/ ctx[1]);
	}

	return {
		c() {
			div4 = element("div");
			div2 = element("div");
			div0 = element("div");
			t0 = text(t0_value);
			t1 = space();
			div1 = element("div");
			t2 = text(t2_value);
			t3 = space();
			div3 = element("div");
			t4 = text(t4_value);
			t5 = space();
			attr(div0, "flex", "");
			attr(div0, "class", "svelte-qw58an");
			attr(div2, "class", "row-x svelte-qw58an");
			attr(div4, "class", "option svelte-qw58an");
		},
		m(target, anchor) {
			insert(target, div4, anchor);
			append(div4, div2);
			append(div2, div0);
			append(div0, t0);
			append(div2, t1);
			append(div2, div1);
			append(div1, t2);
			append(div4, t3);
			append(div4, div3);
			append(div3, t4);
			append(div4, t5);

			if (!mounted) {
				dispose = listen(div4, "click", click_handler);
				mounted = true;
			}
		},
		p(new_ctx, dirty) {
			ctx = new_ctx;
			if (dirty & /*filteredPlano*/ 16 && t0_value !== (t0_value = /*chamada*/ ctx[1] + "")) set_data(t0, t0_value);
			if (dirty & /*filteredPlano*/ 16 && t2_value !== (t2_value = /*classificacao*/ ctx[13] + "")) set_data(t2, t2_value);
			if (dirty & /*filteredPlano*/ 16 && t4_value !== (t4_value = /*descricao*/ ctx[14] + "")) set_data(t4, t4_value);
		},
		d(detaching) {
			if (detaching) detach(div4);
			mounted = false;
			dispose();
		}
	};
}

function create_fragment(ctx) {
	let div1;
	let input;
	let t;
	let div0;
	let mounted;
	let dispose;
	let each_value = /*filteredPlano*/ ctx[4];
	let each_blocks = [];

	for (let i = 0; i < each_value.length; i += 1) {
		each_blocks[i] = create_each_block(get_each_context(ctx, each_value, i));
	}

	return {
		c() {
			div1 = element("div");
			input = element("input");
			t = space();
			div0 = element("div");

			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].c();
			}

			attr(input, "type", "text");
			attr(div0, "id", "overflow");
			attr(div0, "class", "svelte-qw58an");
			attr(div1, "id", "main");
			attr(div1, "class", "svelte-qw58an");
		},
		m(target, anchor) {
			insert(target, div1, anchor);
			append(div1, input);
			set_input_value(input, /*filter*/ ctx[0]);
			/*input_binding*/ ctx[8](input);
			append(div1, t);
			append(div1, div0);

			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].m(div0, null);
			}

			/*div1_binding*/ ctx[10](div1);

			if (!mounted) {
				dispose = [
					listen(input, "input", /*input_input_handler*/ ctx[7]),
					listen(div1, "click", click_handler_1)
				];

				mounted = true;
			}
		},
		p(ctx, [dirty]) {
			if (dirty & /*filter*/ 1 && input.value !== /*filter*/ ctx[0]) {
				set_input_value(input, /*filter*/ ctx[0]);
			}

			if (dirty & /*onClick, filteredPlano*/ 48) {
				each_value = /*filteredPlano*/ ctx[4];
				let i;

				for (i = 0; i < each_value.length; i += 1) {
					const child_ctx = get_each_context(ctx, each_value, i);

					if (each_blocks[i]) {
						each_blocks[i].p(child_ctx, dirty);
					} else {
						each_blocks[i] = create_each_block(child_ctx);
						each_blocks[i].c();
						each_blocks[i].m(div0, null);
					}
				}

				for (; i < each_blocks.length; i += 1) {
					each_blocks[i].d(1);
				}

				each_blocks.length = each_value.length;
			}
		},
		i: noop,
		o: noop,
		d(detaching) {
			if (detaching) detach(div1);
			/*input_binding*/ ctx[8](null);
			destroy_each(each_blocks, detaching);
			/*div1_binding*/ ctx[10](null);
			mounted = false;
			run_all(dispose);
		}
	};
}

const click_handler_1 = e => {
	e.stopPropagation();
};

function instance($$self, $$props, $$invalidate) {
	const dispatch = createEventDispatcher();
	let { chamada } = $$props;
	let { plano } = $$props;
	let { filter } = $$props;
	let mainEl, filterEl, filteredPlano;

	onMount(() => {
		$$invalidate(0, filter = chamada);
		filterEl.focus();

		setTimeout(
			() => {
				filterEl.select();
			},
			0
		);

		$$invalidate(2, mainEl.setFilter = setFilter, mainEl);
	});

	const onClick = chamada => {
		dispatch("select", chamada);
	};

	const setFilter = _filter => {
		$$invalidate(0, filter = _filter);
	};

	function input_input_handler() {
		filter = this.value;
		$$invalidate(0, filter);
	}

	function input_binding($$value) {
		binding_callbacks[$$value ? "unshift" : "push"](() => {
			filterEl = $$value;
			$$invalidate(3, filterEl);
		});
	}

	const click_handler = chamada => {
		onClick(chamada);
	};

	function div1_binding($$value) {
		binding_callbacks[$$value ? "unshift" : "push"](() => {
			mainEl = $$value;
			$$invalidate(2, mainEl);
		});
	}

	$$self.$$set = $$props => {
		if ("chamada" in $$props) $$invalidate(1, chamada = $$props.chamada);
		if ("plano" in $$props) $$invalidate(6, plano = $$props.plano);
		if ("filter" in $$props) $$invalidate(0, filter = $$props.filter);
	};

	$$self.$$.update = () => {
		if ($$self.$$.dirty & /*plano, filter*/ 65) {
			$: $$invalidate(4, filteredPlano = plano.filter(({ chamada, classificacao, descricao }) => `${chamada} ${classificacao} ${descricao}`.search(new RegExp(filter, "i")) >= 0).sort((a, b) => a.classificacao.localeCompare(b.classificacao)));
		}
	};

	return [
		filter,
		chamada,
		mainEl,
		filterEl,
		filteredPlano,
		onClick,
		plano,
		input_input_handler,
		input_binding,
		click_handler,
		div1_binding
	];
}

class ContaDropdown extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance, create_fragment, safe_not_equal, { chamada: 1, plano: 6, filter: 0 });
	}
}

export default ContaDropdown;